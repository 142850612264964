<template>
  <div class="home">
    <svg-icon class="home__pixel" name="home_pixel"></svg-icon>
    <h1 class="home__title">Демогратія: особиста безпека під час війни</h1>
    <p class="home__text">
      Ця симуляційна гра — продовження інтерактивної історії
      <a href="https://www.demogratia.platfor.ma/" target="_blank"
        >ДемоГРАтія.</a
      >
      Цього разу ситуації будуть про безпеку під час війни. Тут ви на практиці
      зможете перевірити власні знання та здатність найкращим чином діяти у
      небезпечних ситуаціях. Спробуйте себе у ролях звичайного громадянина,
      волонтера та голови гуманітарного штабу.
    </p>
    <p class="home__text">
      Розробники гри керувалися офіційними джерелами та матеріалами, з якими ви
      можете ознайомитись нижче. Але пам'ятайте, що у цих ігрових ситуаціях, як
      і в реальності, не завжди є ідеально правильні відповіді. Бережіть себе і
      слава Україні!
    </p>
    <ButtonBase @click="startGame" class="home__start"> Почати гру </ButtonBase>
    <h3 class="home__footer-title">
      Під час розробки гри використані наступні джерела
    </h3>
    <a
      class="home__link"
      href="https://life.pravda.com.ua/society/2022/03/1/247611/"
      target="_blank"
      >Як поводитися під час обстрілів
    </a>
    <a
      class="home__link"
      href="https://osvita.diia.gov.ua/courses/very-verified"
      target="_blank"
      >Курс з медіаграмотності
    </a>
    <a
      class="home__link"
      href="http://jurfem.com.ua/krym-vidpovidalnist-za-kolaboratsiynu-diyalnist/"
      target="_blank"
      >Про колабораціонізм
    </a>
    <a
      class="home__link"
      href="https://www.rbc.ua/ukr/styler/vozdushnaya-trevoga-pochemu-nelzya-ignorirovat-1647972300.html"
      target="_blank"
      >Як поводитися під час повітряної тривоги
    </a>
    <a
      class="home__link"
      href="https://www.unian.ua/society/trivozhna-valiza-shcho-obov-yazkovo-poklasti-novini-ukrajini-11683948.html"
      target="_blank"
      >Як зібрати тривожну валізку
    </a>
    <a
      class="home__link"
      href="https://www.unian.ua/war/pravilo-dvoh-stin-v-budinku-de-naybezpechnishe-pri-vibuhu-novini-vtorgnennya-rosiji-v-ukrajinu-11744788.html"
      target="_blank"
      >Що таке правило двох стін?
    </a>
    <a
      class="home__link"
      href="https://kyivcity.gov.ua/likarni_ta_medytsyna/persha_dolikarska_dopomoha/ItemText_849/"
      target="_blank"
      >Що робити при опіках?
    </a>
    <a
      class="home__link"
      href="https://mkip.gov.ua/news/6971.htmll"
      target="_blank"
      >Що заборонено публікувати під час війни?
    </a>
    <a
      class="home__link"
      href="https://rubryka.com/article/security-rules-occupation/"
      target="_blank"
      >Правила безпеки в окупації
    </a>
    <a
      class="home__link"
      href="https://ssu.gov.ua/yak-diiaty-yakshcho-vyiavyly-pidozrilyi-predmet"
      target="_blank"
      >Як діяти, якщо ви виявили підозрілий предмет?
    </a>
    <a
      class="home__link"
      href="https://jurliga.ligazakon.net/ru/news/209856_chotiri-umovi-viluchennya-avto-dlya-vonnikh-potreb"
      target="_blank"
      >Умови, за яких військові можуть вилучати вашу машину
    </a>
    <a
      class="home__link"
      href="https://www.rv.gov.ua/shcho-robiti-u-razi-himichnoyi-ataki-abo-avariyi-na-himpidpriyemstvi"
      target="_blank"
      >Як поводитися під час небезпеки ураження хімічними речовинами
    </a>
    <h3 class="home__footer-title-about">Розповісти про гру друзям</h3>
    <div class="social">
      <ShareNetwork
        network="facebook"
        url="https://www.demogratia-2.platfor.ma/"
        title="Я пройшов «Демогратію: особиста безпека під час війни» і тобі раджу спробувати"
        description="Я пройшов «Демогратію: особиста безпека під час війни» і тобі раджу спробувати"
      >
        <img class="facebook-icon" src="facebook.png" />
      </ShareNetwork>
      <ShareNetwork
        network="twitter"
        url="https://www.demogratia-2.platfor.ma/"
        title="Я пройшов «Демогратію: особиста безпека під час війни» і тобі раджу спробувати"
        description="Я пройшов «Демогратію: особиста безпека під час війни» і тобі раджу спробувати"
      >
        <img class="twitter-icon" src="twitter.png" />
      </ShareNetwork>
    </div>
    <h3 class="home__footer-title-about">Про проєкт</h3>
    <p class="home__footer-subtitle">
      Гра розроблена на замовлення
      <a href="https://ifesukraine.org/" target="_blank">IFES Україна.</a>
    </p>
    <p class="home__footer-subtitle">
      Розробники гри: Юрій Марченко та Криж Мирослав. Автор ілюстрацій: Євгенія
      Гудкова.
    </p>
    <p class="home__footer-text">
      Зусилля Міжнародної фундації виборчих систем (IFES) щодо зміцнення
      демократичного прогресу в Україні підтримуються Агентством США з
      міжнародного розвитку (USAID), Міністерством міжнародних справ Канади та
      британською допомогою від уряду Великої Британії. Будь-які викладені тут
      думки належать авторам і не обов’язково відображають погляди USAID, урядів
      США, Канади та Великої Британії.
    </p>
    <h3 class="home__footer-title-about">Зворотній зв'язок</h3>
    <p class="home__footer-subtitle">
      Допоможіть зробити цю гру краще! Заповніть, будь ласка, цю
      <a
        href="https://forms.office.com/pages/responsepage.aspx?id=sVBpIovPr0mQQCHOLMxsztmF0GXO1h9Hl-0wp9D2znhUNVIyTjRSVVlENzhOSzZWMDJGVTRIRDlESSQlQCN0PWcu"
        target="_blank"
        >коротку форму.</a
      >
    </p>
    <div class="partners">
      <img class="logo-1" :src="'./logo_1.png'" alt="" />
      <img class="logo-2" :src="'./logo_2.png'" alt="" />
      <img class="logo-3" :src="'./logo_3.png'" alt="" />
      <img class="logo-4" :src="'./logo_4.png'" alt="" />
      <img class="logo-5" :src="'./logo_5.png'" alt="" />
    </div>
    <div class="background">
      <img :src="'./home_background.png'" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  methods: {
    startGame() {
      this.$router.push({ name: "role-select" });
    },
  },
};
</script>

<style lang="sass" scoped>
.home
  padding: 8rem 10.8rem 4rem
  display: flex
  flex-direction: column
  background-color: $background-color
  position: relative
  @include xl
    padding: 4rem 4rem 2rem
  @include m
    padding: 2rem 1.6rem 2rem


.home__pixel
  z-index: 1
  max-width: 6.4rem
  max-height: 6.4rem
  margin-bottom: 3.2rem
  @include xl
    max-width: 4rem
    max-height: 4rem
    margin-bottom: 2rem

.home__title
  z-index: 1
  margin-bottom: 2.8rem
  max-width: 50%
  background-color: $background-color
  @include xl
    max-width: 75%
  @include m
    max-width: 100%

.home__text
  z-index: 1
  margin-bottom: 4rem
  max-width: 50%
  background-color: $background-color
  @include xl
    max-width: 75%
  @include m
    max-width: 100%

.home__start
  z-index: 1
  max-width: 24rem
  margin-bottom: 15.7rem
  @include xl
    margin-bottom: 6rem
  @include m
    margin-bottom: 4rem
  @include ms
    width: 100%
    max-width: none

.home__footer-title
  z-index: 1
  background-color: $background-color
  margin-bottom: 3.2rem
  max-width: 50%
  @include xl
    max-width: 75%
  @include m
    max-width: 100%

.home__footer-subtitle
  z-index: 1
  max-width: 50%
  @include xl
    max-width: 75%
  @include m
    max-width: 100%
  & span
    font-weight: 600

.home__footer-text
  z-index: 1
  background-color: $background-color
  font-size: 1.4rem
  line-height: 2.8rem
  color: $text-primary-color
  margin-bottom: 2rem
  max-width: 50%
  margin-top: 1.6rem
  @include xl
    max-width: 75%
  @include m
    max-width: 100%

.background
  position: absolute
  width: 50vw
  right: 6rem
  margin-left: auto
  max-height: calc(100% - 8rem)
  overflow: hidden
  @include m
    display: none
  & svg
    width: 100%
    height: 100%

.partners
  z-index: 1
  display: flex
  gap: 3.2rem
  align-items: center
  flex-wrap: wrap
  justify-content: center
  background-color: $background-color
  margin-top: 6.4rem
  // @include ms
  //   gap: 1.6rem

.logo-1
  width: 16.6rem
  @include m
    width: 10rem

.logo-2
  width: 15.5rem
  @include m
    width: 10rem

.logo-3
  width: 7.8rem
  @include m
    width: 8rem

.logo-4
  width: 9rem
  @include m
    width: 7rem

.logo-5
  width: 21.2rem
  @include m
    width: 15rem

.home__link
  font-size: 1.6rem
  line-height: 2.4rem
  color: $text-primary-color
  &::before
    content: "•"
    margin-right: 0.8rem

.home__footer-title-about
  background-color: $background-color
  margin: 4rem 0 3.2rem
  z-index: 1
  max-width: 50%
  @include xl
    max-width: 75%
  @include m
    max-width: 100%

.home__feedback
  z-index: 1
  font-size: 1.4rem
  line-height: 2.8rem
  color: $text-primary-color
  max-width: 50%
  margin-bottom: 2rem
  @include xl
    max-width: 75%
  @include m
    max-width: 100%

.social
  display: flex
  gap: 3.2rem
  justify-content: flex-start
  max-width: 50%
  @include xl
    max-width: 75%
  @include m
    max-width: 100%
  @include ms
    justify-content: center

.facebook-icon, .twitter-icon
  max-width: 6.4rem
  max-height: 6.4rem
  @include m
    max-width: 4.2rem
    max-height: 4.2rem
</style>
